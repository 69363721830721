// request controller
import logger from "../utils/logger";
import axiosClient from "./axiosWrapper";

export const rejectRegisterRequest = async (username) => {
  logger('rejectRegisterRequest', username)
  let result = await axiosClient().post('/requests/reject', {username: username})
  logger(result)
  return result
}

export const approveRegisterRequest = async (username) => {
  logger('approveRegisterRequest', username)
  let result = await axiosClient().post('/requests/approve', {username: username})
  logger(result)
  return result
}

export const getRequests = async () => { //no params
                                         //try axiosClient.get()
  logger('getRequests')
  return await axiosClient().get('/users?type=requests')
}
