import logger from "./logger";

export function compare(fieldName, a, b ) {
  if ( a[fieldName] < b[fieldName] ){
    return -1;
  }
  if ( a[fieldName] > b[fieldName] ){
    return 1;
  }
  return 0;
}

export const fieldSorter = (fields) => (a, b) => fields.map(o => {
  let dir = 1;
  if (o[0] === '-') { dir = -1; o=o.substring(1); }
  return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

export const splitFullname = (fullname) => {
  if (!fullname) return {}
  logger('fullname', fullname)
  let data = fullname.split(' ')
  return {lastName: data[0], name: data[1], patronymic: data[2]}
}

